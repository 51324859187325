import React from 'react';
import db from "store/database.json";
import BrandLogoSlider from "components/BrandLogoSlider";

export default function Services() {
    const serviceDB = db.service;
    const renderList = (list) => {
        return list.map((item,i) => {
            return (
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                            <div className="bg-image" style={{ backgroundImage: `url(/assets/img/service/${item.image})`}} />
                        </div>
                        <div className="icon">
                            <i className={`icon-awesome ${item.icon}`} />
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">{item.label}</h3>
                        <p className="subtitle">{item.text}</p>
                    </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="service-page">
            <div className="breadcrumb-area breadcrumb-bg">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="page-banner text-center">
                                <h1>{serviceDB.section}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-wrapper section-space--inner--60">
                <div className="service-section">
                    <div className="container">
                        <p className="service-desc">{serviceDB.desc}</p>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-item-wrapper">
                                    <div className="row">
                                        {renderList(serviceDB.list)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
            <BrandLogoSlider line />
        </div>
    )
}
