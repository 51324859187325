import React, { useState } from "react";
import { Dialog, Tooltip } from "@material-ui/core";
import db from "store/database.json";

export default function Team() {
    const teamDB = db.team;
    const [open, setOpen] = useState(false);
    const [detail, setDetail] = useState("")

    const handleClose = () => {
        setOpen(false)
        setDetail("")
    }


    const openDetail = (person) => {
        setOpen(true)
        setDetail(person)
    }

    return (
        <div className="team-page">
            <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
                    <Tooltip title="Close"><div className="modal-close" onClick={handleClose}><i className="ion-android-close"></i></div></Tooltip>
                    <div className="container">                        
                        <div className="row">
                            <div className="col-lg-5 col-12">
                                <div className="bg-image team" style={{ backgroundImage: `url(/assets/img/team/${detail.image})`}} />
                            </div>
                            <div className="col-lg-7 col-12">
                                <div className="team-wrap">
                                    <div className="team-info">
                                        <h4>{detail.name}</h4>
                                        <h5>{detail.role}</h5>
                                        <p>{detail.text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
            </Dialog>
            <div className="breadcrumb-area breadcrumb-bg">
                <div className="container">
                <div className="row">
                    <div className="col">
                    <div className="page-banner text-center">
                        <h1>{teamDB.section}</h1>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="page-wrapper">
                <div className="section-space--inner--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="about-content-two">
                                    <p>{teamDB.desc}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row team-list">                            
                            {teamDB.list.map((item, key) => (
                                <div className="col-lg-4 col-md-6 col-12 team-person" key={key}>
                                    <div className="single-gallery-project">
                                        <div className="bg-image series" style={{ backgroundImage: `url(/assets/img/team/${item.image})`}} />
                                        <div className="single-gallery-project__content">
                                            <h3 className="title">{item.name}</h3>
                                            <h5 className="sub-title">{item.role}</h5>
                                            <button title={item.name} onClick={() => openDetail(item)}>SEE PERSONAL RESUME</button>
                                        </div>
                                    </div>                    
                                </div>
                            ))}      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}