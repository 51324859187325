import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import MobileMenu from "./components/MobileMenu";
import About from './pages/About';
import CompletedList from "./pages/Projects/CompletedList";
import CompletedDetail from "./pages/Projects/CompletedDetail";
import Services from './pages/Services';
import Contact from './pages/Contact';
import PageNotFound from './pages/404';
import NoMAtch from './pages/404';
import Team from "./pages/Team";

import {BrowserRouter, Switch, Route, useLocation} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';



function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function Root() {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            <NavBar />
            <Switch>
                <Route exact path={`/`} component={About}/>
                <Route exact path={`/team`} component={Team}/>
                <Route exact path={`/services`} component={Services}/>
                <Route exact path={`/completed-projects`} component={CompletedList}/>
                <Route exact path={`/completed-projects/:id/:slug`} component={CompletedDetail}/>
                <Route exact path={`/contact-us`} component={Contact}/>
                <Route exact path={`/404`} component={PageNotFound}/>
                <Route component={NoMAtch} />
            </Switch>
            <Footer/>
            <MobileMenu/>
        </BrowserRouter>
    )
}

ReactDOM.render(<Root/>, document.getElementById('root'));

serviceWorker.register();