import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import db from "store/projects.json";
import { paginate } from "store/helpers";

export default function CompletedList() {
    const completedP = db.completed;
    const [pageSize] = useState(6);
    const [pageNumber] = useState(1);
    const [list, setList] = useState([]);
    const [pageCount] = useState(Math.ceil(completedP.list.length/pageSize))

    useEffect(() => {
        async function onFetch() {
            const list = await paginate(completedP.list, pageSize, pageNumber);
            setList(list);
        }
        onFetch()
    }, [pageNumber, pageSize, completedP.list])

    const renderList = (list) => {
        return list.map((item, i) => {
            const link = `${process.env.PUBLIC_URL}/completed-projects/${item.id}/${item.slug}`
            return(
                <div className="col-lg-4 col-sm-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item service-grid-item--style2">
                        <div className="service-grid-item__image">
                            <div className="service-grid-item__image-wrapper">
                                <Link to={link}>
                                    <img src={`/assets/img/projects/${item.id}/${item.img_view}`} className="img-fluid" alt={`project-${item.id}`} />
                                </Link>
                            </div>
                        </div>
                        <div className="service-grid-item__content">
                            <h3 className="title">
                                <Link to={link}>{item.label}</Link>
                            </h3>
                            <div className="subtitle" dangerouslySetInnerHTML={{ __html: item.sizes[0] }} />
                            <Link to={link} className="see-more-link">SEE MORE</Link>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const onPageChange = async (page) => {
        const { selected } = page
        const list = await paginate(completedP.list, pageSize, selected + 1);
        setList(list);
    }

    return (
        <div className="completed-projects">
            <div className="breadcrumb-area breadcrumb-bg">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="page-banner text-center">
                                <h1>Completed Projects</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-wrapper section-space--inner--120">
                <div className="project-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="project-item-wrapper">
                                    <div className="row">
                                        {renderList(list)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row section-space--top--60">
                            <div className="col">
                                <ReactPaginate
                                    previousLabel={<span><i className="fa fa-angle-left" /> Prev</span>}
                                    nextLabel={<span><i className="fa fa-angle-right" /> Next</span>}
                                    breakLabel={'...'}
                                    disabledClassName={"disabled"}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={onPageChange}
                                    containerClassName={'page-pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}