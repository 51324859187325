import React, { useState } from 'react'

export default function QuoteForm(props) {
    const [inputValues, setInputValues] = useState({
        name: "",
        email:"",
        message:""
    })

    const handleChange = event => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const onSubmit = async e => {
        e.preventDefault();
        await props.handleSubmit(inputValues);
        setInputValues({
            name: "",
            email:"",
            message:""
        })
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="row row-10">
                <div className="col-md-6 col-12 section-space--bottom--20">
                    <input 
                        name="name" type="text" 
                        placeholder="Your Name" 
                        autoComplete="off"
                        value={inputValues.name}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-md-6 col-12 section-space--bottom--20">
                    <input 
                        name="email" type="email" 
                        placeholder="Your Email"
                        autoComplete="off"
                        value={inputValues.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-12">
                    <textarea 
                        name="message" placeholder="Your Message" 
                        autoComplete="off"
                        value={inputValues.message} 
                        onChange={handleChange}
                    />
                </div>
                <div className="col-12">
                    <button type="submit">Send Message</button>
                </div>
            </div>
        </form>
    )
}
