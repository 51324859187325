import React from 'react';
import { Link } from "react-router-dom";
import _ from "lodash";
import db from "store/projects.json"
import SliderGallery from "components/SliderGallery"

export default function CompletedDetail({ match }) {

    const list = db.completed.list;
    const detail = _.filter(list, { "id": parseInt(match.params.id) });
    const content = detail[0];

    return (
        <div>
            <div className="breadcrumb-area breadcrumb-bg">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="page-banner text-center">
                                <h1>Project Details</h1>
                                <ul className="page-breadcrumb">
                                    <li><Link to="/completed-projects">Completed Projects</Link></li>
                                    <li>{content.label}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-wrapper section-space--inner--60">
                <div className="project-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-12 section-space--bottom--40">                            
                            <SliderGallery path="/assets/img/projects" id={match.params.id} images={content.images} />
                        </div>
                    </div>
                    <div className="row">                        
                        <div className="col-lg-5 col-12 section-space--bottom--30">
                            <div className="project-information">
                                <h3>Project Information</h3>
                                <ul>
                                    <li><strong>Client:</strong><span>{content.client}</span></li>
                                    <li><strong>Location:</strong><span>{content.location}</span></li>
                                    <li><strong>Item:</strong><span>{content.item}</span></li>
                                    <li><strong>Time:</strong><span>{content.time}</span></li>
                                    <li><strong>Value:</strong><span>{content.value}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                                <h2>{content.label}</h2>
                                <h3>Scope of Project</h3>
                                <ul>{content.scopes.map((scope, key) => <li key={key}><p>{scope}</p></li>)}</ul>
                                <h3>Work size</h3>
                                <ul>{content.sizes.map((size, key) => <li key={key} dangerouslySetInnerHTML={{ __html: size }} />)}</ul>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}