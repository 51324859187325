import React from "react";
import { Link } from "react-router-dom"
import FeatureIcon from "components/FeatureIcon";
import Certificate from "components/Certificate";
import BrandLogoSlider from "components/BrandLogoSlider";
import db from "store/database.json";

export default function About() {
  const aboutDB = db.about;
  return (
    <div className="about-page">        
      <div className="breadcrumb-area breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="page-banner text-center">
                <h1>{aboutDB.section}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-wrapper">
        <div className="section-space--inner--60">
          <div className="container">
            <div className="row row-25 align-items-center">
              <div className="col-12 ">
                <div className="about-content-two text-center">
                  <h2>{aboutDB.title}</h2>
                  <p dangerouslySetInnerHTML={{ __html: aboutDB.sub_title}} />
                </div>
              </div>
            </div>
            <div className="about-wrapper row section-space--inner--top--30">
              <div className="col-sm-6 col-12 order-1 order-lg-2">
                <div className="about-image about-image-1">
                  <Link
                    to={`${process.env.PUBLIC_URL}/completed-projects/1/east-sai-gon`}
                    title="East Sai Gon"
                  >
                    <div className="bg-image" style={{ backgroundImage: `url("/assets/img/about/about-1.jpg")`,  paddingTop: "65%" }} />
                    <div className="single-gallery-project__content">
                      <h4 className="title">East Sai Gon PROJECT</h4>
                    </div>
                  </Link>                  
                </div>
              </div>
              <div className="col-sm-6 col-12 order-2 order-lg-3">
                <div className="about-image about-image-2">
                  <Link
                    to={`${process.env.PUBLIC_URL}/completed-projects/4/dongphosy-specific-economic-zone`}
                    title="Dongphosy Specific Economic Zone"
                  >
                    <div className="bg-image" style={{ backgroundImage: `url("/assets/img/about/about-2.jpg")`,  paddingTop: "65%" }} />
                    <div className="single-gallery-project__content">
                      <h4 className="title">DONGPHOSY SEZ PROJECT</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-12 order-3 order-lg-1">
                <div className="about-content about-content-1">
                  <h3>{aboutDB.heading_1}</h3>
                  <p>We build high quality <strong>Infrastructure, Civil and MEP</strong> works that facilitate comfortable living and long-term progress.</p>
                </div>
              </div>
              <div className="col-lg-6 col-12 order-4">
                <div className="about-content about-content-2">
                  <h3>{aboutDB.heading_2}</h3>
                  <p>{aboutDB.text_2}</p>
                  <a
                    href={`${process.env.PUBLIC_URL}/contact-us`}
                    className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                  >
                    Contact us
                  </a>
                </div>
              </div>
            </div>
            <div className="about-wrapper row section-space--inner--top--120">
              <div className="col-sm-6 col-12 order-1 order-lg-2">
                <div className="about-image about-image-2 about-image-3">
                  <Link
                    to={`${process.env.PUBLIC_URL}/completed-projects/2/swan-city`}
                    title="Swan City"
                  >
                    <div className="bg-image" style={{ backgroundImage: `url("/assets/img/projects/2/02.jpg")`,  paddingTop: "65%" }} />
                    <div className="single-gallery-project__content">
                      <h4 className="title">Swan City Project</h4>
                    </div>
                  </Link>   
                  
                </div>
              </div>
              <div className="col-sm-6 col-12 order-3 order-lg-1">
                <div className="about-image about-image-2 about-image-3">
                  <Link
                    to={`${process.env.PUBLIC_URL}/completed-projects/7/celadon-city`}
                    title="Celadon City"
                  >
                    <div className="bg-image" style={{ backgroundImage: `url("/assets/img/about/about-3.jpg")`,  paddingTop: "65%" }} />
                    <div className="single-gallery-project__content">
                      <h4 className="title">Celadon City Project</h4>
                    </div>
                  </Link>   
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <FeatureIcon background="grey-bg" />
        <Certificate />
        <BrandLogoSlider />
      </div>
    </div>
  )
}