import React, { Component } from 'react';

class Certificate extends Component{
    render(){

        return(
            <div className={`feature-section section-space--inner--100 ${this.props.background}`}>
                <div className="container">
                    <h3 className="section-title cc-title">Our Certificate</h3>
                    <div className="col-lg-12">
                        <div className="feature-item-wrapper">
                            <div className="row justify-content-center">
                                <img src="/assets/img/backgrounds/certificate.jpg" alt="certificate" className="img-fluid" />
                            </div>
                            <div className="row justify-content-center">
                                <div className="caption">Certificate of Construction Activity Capability Level 2</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Certificate;