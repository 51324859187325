import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import MobileMenu from './MobileMenu';
import db from "../store/database.json"

const links = [
    { name: "ABOUT", path: "/" },
    { name: "OUR TEAM", path: "/team" },
    { name: "SERVICE", path: "/services" },
    { 
        name: "PROJECT",
        sub: [
            { name: "COMPLETED PROJECTS", path: "/completed-projects" }
        ] 
    }, 
    { name: "CONTACT", path: "/contact-us" }
]
class NavBar extends Component{

    constructor(props){
        super(props);
        this.state = {};
        this.handleScroll = this.handleScroll.bind(this);

        this.mobileMenuElement = React.createRef();
    }

    activeMobileMenu = () => {
        this.mobileMenuElement.current.toggleMobileMenu();
    }

    handleScroll() {
        if(this.mount){
            this.setState({scroll: window.scrollY});
        }
    }
  
    componentDidMount() {
        this.mount = true;
        const el = document.querySelector('nav');
        this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentDidUpdate() {
        this.state.scroll > this.state.top ? 
        document.body.style.paddingTop = `${this.state.height}px` :
        document.body.style.paddingTop = 0;
    }

    componentWillUnmount(){
        this.mount = false;
    }

    render(){
        const contactDB = db.contact;
        return(
            <div>
                <div className={`header-area header-sticky header-sticky--default ${this.state.scroll > this.state.top ? "is-sticky" : ""}`}>
                    <div className="header-area__desktop header-area__desktop--default">
                    <div className="header-info-area">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                <div className="header-info-wrapper align-items-center">
                                    <div className="logo">
                                        <Link to = {`${process.env.PUBLIC_URL}/`}>
                                            <img src="/assets/img/logo/cienco-logo.png" className="img-fluid" alt="cienco-construction" />
                                        </Link>
                                    </div>
                                    <div className="header-contact-info">
                                    <div className="header-info-single-item">
                                        <div className="header-info-single-item__icon">
                                        <i className="zmdi zmdi-smartphone-android" />
                                        </div>
                                        <div className="header-info-single-item__content">
                                        <h6 className="header-info-single-item__title">Contact</h6>
                                        <p className="header-info-single-item__subtitle">
                                            {contactDB.phone}<br />
                                            {contactDB.email}                                            
                                        </p>
                                        </div>
                                    </div>
                                    <div className="header-info-single-item">
                                        <div className="header-info-single-item__icon">
                                        <i className="zmdi zmdi-home" />
                                        </div>
                                        <div className="header-info-single-item__content">
                                        <h6 className="header-info-single-item__title">Address</h6>
                                        <p className="header-info-single-item__subtitle">
                                            {contactDB.address}
                                        </p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="mobile-navigation-icon" id="mobile-menu-trigger" onClick={this.activeMobileMenu}>
                                        <i />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>
                    <div className="header-navigation-area default-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="header-navigation header-navigation--header-default position-relative">
                                        <div className="header-navigation__nav position-static">
                                        <nav>
                                            <ul>
                                            {
                                                links.map((link, key) => {
                                                    if (link.sub) {
                                                        return (
                                                            <li className="has-children has-children--multilevel-submenu" key={key}>
                                                                <Link to="/" onClick={(e) => e.preventDefault()} >{link.name}</Link>                                                
                                                                <ul className="submenu">
                                                                    {link.sub.map((sub, i) => <li key={i}><Link to={sub.path} >{sub.name}</Link></li>)}
                                                                </ul>
                                                            </li>
                                                        ) 
                                                    }

                                                    return <li key={key}><Link to={link.path}>{link.name}</Link></li>
                                                })
                                            }
                                            </ul>
                                        </nav>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <MobileMenu ref={this.mobileMenuElement} />
            </div>
        )
    }
}


export default NavBar;