import React, { Component } from 'react';
const data = [
    { 
        icon: "feature-1.png", 
        title: "High quality", 
        content: "We’re committed to delivering high quality works that meet international standards and live up to your expectations."
    },
    { 
        icon: "feature-calendar.png", 
        title: "Schedule compliance", 
        content: "We always put in our best performance with a strong sense of responsibility to complete your project on time."
    },    
    { 
        icon: "feature-2.png", 
        title: "Professional management", 
        content: "Our highly-skilled and experienced project management team is responsive and easy to work with. We ensure the smooth execution of your project by focusing on good communication, coordination and planning."
    },
    { 
        icon: "feature-cash.png", 
        title: "Good financial capacity", 
        content: "Our financial capacity is supported by one of the biggest banks in Vietnam – ViettinBank, allowing us to carry through even big projects."
    },
    { 
        icon: "feature-3.png", 
        title: "Competitive prices", 
        content: "We offer the best price for your budget with the best quality possible."
    }
]

class FeatureIcon extends Component{
    render(){
        let Datalist = data.map((val, i)=>{
            return(
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="feature">
                    <div className="icon">
                        <img src={`assets/img/icons/${val.icon}`} className="img-fluid" alt="" />
                    </div>
                    <div className="content">
                        <h3>{val.title}</h3>
                        <p>{val.content}</p>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div className={`feature-section section-space--inner--100 ${this.props.background}`}>
                <div className="container">
                    <h3 className="section-title cc-title">We Offer</h3>
                    <div className="col-lg-12">
                        <div className="feature-item-wrapper">
                            <div className="row justify-content-center">
                                {Datalist}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeatureIcon;