import React from 'react';
import Swiper from 'react-id-swiper';

export default function SliderGallery({ path, images, id }) {
    const params = {
        slidesPerView : 1,
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 2500
        },
        navigation: {
            nextEl: '.ht-swiper-button-next',
            prevEl: '.ht-swiper-button-prev'
        },
        renderPrevButton: () => (
            <div className="ht-swiper-button-prev ht-swiper-button-nav"><i className="ion-ios-arrow-left" /></div>
        ),
        renderNextButton: () => (
            <div className="ht-swiper-button-next ht-swiper-button-nav"><i className="ion-ios-arrow-forward" /></div>
        )
    };
    return(
        <div>
            <div className="service-gallery slider-gallery">
                <Swiper {...params}>
                    {images.map((val, i) => {
                        return(
                            <div className="swiper-slide service-gallery__single-slide" key={i}>
                                <div className="item">
                                    <div className="bg-image" style={{ backgroundImage: `url(${path}/${id}/${val})`}} />
                                </div>
                            </div>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    )
}