import React, {Component} from 'react';
import Swiper from 'react-id-swiper';
class BrandLogoSlider extends Component{
    render(){

        const params = {
            slidesPerView : 8,
            loop: true,
            speed: 1000,
            spaceBetween : 30,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
           
            // Responsive breakpoints
            breakpoints: {
                1499:{
                    slidesPerView : 4
                },

                991:{
                    slidesPerView : 3
                },

                767:{
                    slidesPerView : 3

                },

                575:{
                    slidesPerView : 2
                }
            }
        }

        let data = [
            "01.png",
            "02.png",
            "03.png",
            "04.png",
            "05.png",
            "06.png",
            "07.png",
            "08.png"
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="swiper-slide brand-logo-slider__single" key={i}>
                    <div className="image text-center">
                        <img src={`/assets/img/brand-logo/${val}`} className="img-fluid" alt={`brand-${i}`} />
                    </div>
                </div>
            )
        });


        return(
            <div className={`brand-logo-slider-area section-space--inner--60 ${this.props.className}`}>
                <div className="container-fluid brand-wrapper">
                    <h3 className="section-title cc-title">Our Clients</h3>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="brand-logo-slider__container-area">
                                <Swiper {...params}>
                                    {DataList}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BrandLogoSlider;