import React from 'react';
import swal from "sweetalert";
import axios from "axios"
import db from "store/database.json";
import QuoteForm from "components/QuoteForm";

export default function Contact () {
    const contactDB = db.contact;
    const onSubmit = async value => {
        const { name, email } = value;

        if(!name) {
            swal({
                text: "Xin điền tên của bạn",
                icon: "warning",
                button: "Close"
            });
            return;
        }

        if(!email) {
            swal({
                text: "Xin điền email của bạn",
                icon: "warning",
                button: "Close"
            });
            return;
        }

        try {
            axios.post("https://us-central1-cienco-contructions.cloudfunctions.net/app/send-email", value);
            swal({
                text: "Lời nhắn của bạn đã được gửi thành công. Chúng tôi sẽ phản hồi sớm nhất có thể",
                icon: "success",
                button: "Close"
            });

        } catch (error) {
            swal({
                text: error.message,
                icon: "warning",
                button: "Close"
            });
        }
    }
    return (
        <div className="contact-page">
            <div className="breadcrumb-area breadcrumb-bg">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="page-banner text-center">
                                <h1>Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="page-wrapper section-space--inner--120">
                <div className="contact-section">
                    <div className="container">
                    <div className="row section-space--bottom--50">
                        <div className="col-lg-4 col-12">
                            <div className="bg-image map" style={{ backgroundImage: `url("/assets/img/backgrounds/building.jpg")` }} alt="cienco" />
                        </div>
                        <div className="col-lg-8 col-12">
                            <div className="contact-map">                                
                                <iframe title="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.205618046126!2d106.66194031532469!3d10.795557992308563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529320c001add%3A0xdf5c5a391377da37!2zMTcxIELDuWkgVGjhu4sgWHXDom4sIFBoxrDhu51uZyAxLCBUw6JuIELDrG5oLCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZpZXRuYW0!5e0!3m2!1sen!2s!4v1602746601292!5m2!1sen!2s" width="600" height="450" allowFullScreen ></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-12">
                        <div className="contact-information">
                            <h3>Contact Information</h3>
                            <ul>
                                <li>
                                    <span className="icon"><i className="ion-android-map" /></span>
                                    <span className="text"><span>{contactDB.full_address}</span></span>
                                </li>
                                <li>
                                    <span className="icon"><i className="ion-ios-telephone-outline" /></span>
                                    <span className="text"><span>{contactDB.phone}</span></span>
                                </li>
                                <li>
                                    <span className="icon"><i className="ion-ios-email-outline" /></span>
                                    <span className="text"><span>{contactDB.email}</span></span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-lg-8 col-12">
                            <div className="contact-form">
                                <h3>Leave Your Message</h3>
                                <QuoteForm handleSubmit={onSubmit} />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}