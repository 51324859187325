import React, {Component} from 'react';
import { Link } from "react-router-dom";

class MobileMenu extends Component{

    state = {
        active : false
    }

    toggleMobileMenu = () => {
        this.setState({
            active: !this.state.active
        })
    }

    componentDidMount(){
        var offCanvasNav = document.getElementById('offcanvas-navigation');
        var offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');

        for (let i = 0; i<offCanvasNavSubMenu.length; i++){
           offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
        }

        var menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
        var numMenuExpand = menuExpand.length;

        function sideMenuExpand() {

            if(this.parentElement.classList.contains('active') === true) {
                this.parentElement.classList.remove('active');
            } else {
                for (let i = 0; i < numMenuExpand; i++) {
                    menuExpand[i].parentElement.classList.remove('active');
                }
                this.parentElement.classList.add('active');
            }
        }

        for (let i = 0; i < numMenuExpand; i++) {
            menuExpand[i].addEventListener("click", sideMenuExpand);
        }
    }


    render(){
        return(
            <div className= {`offcanvas-mobile-menu ${this.state.active ? 'active': ''} `} id="mobile-menu-overlay">
                <a href="#/" className="offcanvas-menu-close" id="mobile-menu-close-trigger" onClick={this.toggleMobileMenu}>
                    <i className="ion-android-close" />
                </a>
                <div className="offcanvas-wrapper">
                    <div className="offcanvas-inner-content">
                        <div className="offcanvas-mobile-search-area">
                            <div className="logo">
                                <a href={`${process.env.PUBLIC_URL}/`}>
                                    <img src="/assets/img/logo/cienco-logo.png" className="img-fluid" alt="cienco-construction" />
                                </a>
                            </div>
                        </div>
                        <nav className="offcanvas-navigation" id="offcanvas-navigation">
                            <ul>
                                <li><a href={`${process.env.PUBLIC_URL}/`}>ABOUT</a></li>
                                <li>
                                    <a href={`${process.env.PUBLIC_URL}/team`}>OUR TEAM</a>
                                </li>
                                <li>
                                    <a href={`${process.env.PUBLIC_URL}/services`}>SERVICE</a>
                                </li>
                                <li className="menu-item-has-children">
                                    <a href="/" onClick={e => e.preventDefault()} >PROJECT</a>
                                    <ul className="sub-menu">
                                        <li><a href={`${process.env.PUBLIC_URL}/completed-projects`} >COMPLETED PROJECTS</a></li>
                                    </ul>
                                </li>
                                <li><a href={`${process.env.PUBLIC_URL}/contact-us`}>CONTACT</a> </li>
                            </ul>
                        </nav>
                        <div className="offcanvas-widget-area">
                            <div className="off-canvas-contact-widget">
                            <div className="header-contact-info">
                                <ul className="header-contact-info__list">
                                <li><i className="ion-android-phone-portrait" /> <Link to="/" onClick={e =>e.preventDefault()}>(1245) 2456 012 </Link></li>
                                <li><i className="ion-android-mail" /> <Link to="/" onClick={e =>e.preventDefault()}>info@yourdomain.com</Link></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileMenu;